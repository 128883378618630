export default {
	countries: {
		now: "Ci sei",
		change: "Modifica",
		name: {
			ES: "Spagna",
			FR: "Francia",
			DE: "Germania",
			IT: "Italia",
			UK: "Gran Bretagna",
			MX: "Messico",
			PT: "Portogallo",
			EUR: "Europa",
			AME: "America",
		},
	},
	global: {
		timeAgoMinutes: "{minutes} min fa",
		timeAgoHours: "{hours} h fa",
		timeAgoHoursMinutes: "{hours} h e {minutes} min fa",
		timeAgoDay: "1 giorno fa",
		timeAgoDays: "{days} giorni fa",
	},
	pages: {
		home: "Inizio",
		leaf: "Scheda prodotto",
		list: "Elenco di",
		contacts: "Contatti",
	},
	components: {
		ProductDescription: {
			readMore: "Leggi di più",
		},
		SliderCardFinder: {
			most_find: "I più cercati::",
		},
	},
	product_types: {
		1: "Scarpe running",
		45: "Orologi sportivi",
		71: "Leggings running",
		72: "Integratori sportivi",
		73: "Smartband",
		74: "Smartwatch",
		75: "Ciclocomputer",
		76: "Cuffie bluetooth wireless",
		84: "Scarpe trekking",
		102: "Occhiali da sole",
		105: "Atrezzi fitness",
	},
	sections: {
		4: "Notizie sulla corsa",
		5: "Allenamento corsa",
		6: "Nutrizione",
		7: "Infortuni sportivi",
		15: "Il blog Mizuno di Raquel",
		31: "Atletas Sketchers",
		32: "Running fashion blogger",
		45: "Podcast di Runnea",
		58: "Maratona di New York",
		63: "Mamme runners",
		71: "Outdoor",
		73: "Team Runnea femminile",
		74: "Elettronica",
	},
	leaf: {
		features: {
			title: "Caratteristiche",
			list: {
				gender: "Genere",
				cushioning: "Ammortizzazione",
				constitution: "Costituzione",
				footprint: "Impronta",
				surface: "Superficie",
				use: "Uso",
				beat: "Ritmo",
				foot: "Piede",
				support: "Appoggio del piede",
				distance: "Distanza",
				weight: "Peso",
				weightf: "Peso Donna",
				weightm: "Peso Uomo",
				drop: "Drop",
				droptie: "Drop",
				colour: "Colore",
				options: "Opzioni",
				sensors: "Sensori",
				connections: "Connnessioni",
				autonomy: "Autonomia (Modo GPS)",
				technology: "Tecnologia",
				impermeability: "Impermeabilità",
				sole: "Suola",
				fabric: "Tessuto",
				sustainability: "Sostenibilitá",
				year: "Anno",
			},
			values: {
				man: "Uomo",
				woman: "Donna",
				low: "Basso",
				medium: "Medio",
				high: "Alto",
				short: "Corto",
				light: "Leggero",
				strong: "Forte",
				neutral: "Neutro",
				supinator: "Supinatore",
				pronator: "Pronatore",
				asphalt: "Asfalto",
				trail: "Trail",
				training: "Allenamento",
				competition: "Gara",
				mixed: "Varie",
				dig: "Cavo",
				flat: "Piano",
				normal: "Normale",
				heel: "Tallone",
				maraton: "Maratona",
				"carbon-plate": "Piastra di carbonio",
				"half-maraton": "Mezza maratona",
				"10k": "10K",
				fitness: "Fitness",
				running: "Running",
				running_shoes: "Scarpe Running",
				cycling: "Ciclismo",
				swimming: "Nuoto/diving",
				outdoor: "Outdoor",
				triathlon: "Triathlon",
				crossfit: "Crossfit",
				trekking: "Trekking",
				gps: "GPS",
				tactile: "Touch screen",
				color_screen: "Schermo-colore",
				maps: "Mappe",
				pulse: "Cardiofrequenzimetro da polso",
				band: "Fascia toracica",
				music: "Musica senza cellulare",
				spotify: "Spotify",
				strava: "Strava",
				altimeter: "Altimetro",
				thermometer: "Termometro",
				tensiometer: "Misura pressione",
				oximeter: "Pulsossimetro",
				barometer: "Barometro",
				bluetooth: "Bluetooth",
				wifi: "WI-FI",
				nfc: "NFC",
				fm: "Radio FM",
				sim: "Carta SIM",
				"1-12": "1 - 12 ore",
				"13-24": "13 - 24 ore",
				"25-36": "25 - 36 ore",
				"37-48": "37 - 48 ore",
				48: "Più di 48 ore",
				yes: "Sì",
				no: "No",
				"dry-line": "Dry-line",
				"gore-tex": "Gore-tex",
				"omni-tech": "Omni-tech",
				outdry: "Outdry",
				contagrip: "Contagrip",
				continental: "Continental",
				frixion: "Frixion",
				michelin: "Michelin",
				vibram: "Vibram",
				tiptoe: "Punta",
				vegans: "Vegane",
				"recycled-material": "Materiale riciclato",
				co2: "Sostenibili CO2",
				yellow: "Amarillo",
				blue: "Azul",
				white: "Blanco",
				grey: "Gris",
				brown: "Marrón",
				purple: "Morado",
				orange: "Naranja",
				black: "Negro",
				red: "Rojo",
				pink: "Rosa",
				green: "Verde",
			},
		},
		performances: {
			title: "Servizi",
			lightness: "Leggerezza",
			cushioning: "Ammortizzazione",
			flexibility: "Flessibilità",
			responsive: "Risposta",
			stability: "Stabilità",
			grip: "Grip",
			durability: "Durata",
			fit: "Vestibilità",
			comfort: "Comodità",
			quality: "Qualità",
		},
	},
	list: {
		filters: {
			title: "Filtri",
			brand: "Marca",
			gender: "Genere",
			price: "Prezzo",
			drop: "Drop",
			droptie: "Drop",
			cushioning: "Ammortizzazione",
			constitution: "Costituzione",
			footprint: "Impronta",
			surface: "Superficie",
			use: "Uso",
			beat: "Ritmo",
			foot: "Piede",
			support: "Appoggio",
			distance: "Distanza",
			sustainability: "Sostenibilitá",
			size: "Taglia",
			technology: "Tecnologia",
			fabric: "Tessuto",
			store: "Negozio",
			colour: "Colore",
			impermeability: "Impermeabilità",
			sole: "Suola",
			options: "Opzioni",
			type: "Tipo",
			category: "Categoria",
			lens_category: "Categoria lente",
			lens_type: "Tipo di lente",
			filter: "Filtri",
			province: "Province",
			region: "Regione",
			country: "Nazione",
			year: "Anno",
			month: "Mese",
			sensors: "Sensori",
			connections: "Connessioni",
			autonomy: "Autonomia (modo GPS)",
		},
		filtersValues: {
			man: "Uomo",
			woman: "Donna",
			kid: "Niño - Bambina",
			low: "Basso",
			medium: "Medio",
			high: "Alto",
			mediumhigh: "Medio alto",
			"carbon-plate": "Piastra di carbonio",
			light: "Leggera",
			soft: "Minimalista",
			strong: "Forte",
			asphalt: "Asfalto",
			trail: "Trail",
			track: "Pista",
			training: "Allenamento",
			competition: "_Gara",
			mixed: "Vari",
			dig: "Cavo",
			flat: "Piano",
			normal: "Normale",
			tiptoe: "Punta",
			heel: "Tallone",
			pronator: "Pronatore",
			neutral: "Neutro",
			supinator: "Supinatore",
			short: "Distanze brevi",
			maraton: "Maratona",
			"half-maraton": "Media maratona",
			"10k": "10K",
		},
	},
	product_sorting: [
		{ value: "recommended_desc", text: "pertinenza" },
		{ value: "date_desc", text: "notizie" },
		{ value: "price_asc", text: "prezzo dal più basso al più alto" },
		{ value: "price_desc", text: "prezzo dal più alto al più basso" },
		{ value: "users_desc", text: "valutazioni degli utenti" },
		{ value: "review_desc", text: "valutazione specialistica" },
		{ value: "discount_desc", text: "più risparmio" },
	],
	price_sorting: [
		{ value: "date_desc", text: "nuovi prodotti" },
		{ value: "price_asc", text: "dal più basso al più alto prezzo" },
		{ value: "price_desc", text: "prezzo dal più alto al più basso" },
	],
	author: {
		written: "Ha scritto",
		of: "da",
		reviews: "recensioni",
		articles: "articoli",
		by: "Da",
		Twitter: "Puoi seguirmi su Twitter",
		Instagram: "Puoi seguirmi su Twitter",
	},
	cookies: {
		text: "Utilizziamo i cookie per mantenere identificati gli utenti registrati e per poter personalizzare i servizi e i cookie analitici di terzi per le statistiche sulle visualizzazioni delle pagine, ma sempre in modo anonimo.",
		button: "Politica dei cookie",
		accept: "Accetto",
		deny: "Non accetto",
	},
	article_list: {
		page_title: "Magazine Running",
		meta_title: "Articoli per la corsa - Scarpe e vestiti da corsa",
		meta_description:
			"Articoli da corsa pubblicati da corridori e dal team di Runnea con consigli su alimentazione, allenamento, scarpe da corsa per correre in modo sano e corretto.",
		page_title_section: "{seo}",
		meta_title_section: "Articoli {seo}",
		meta_description_section:
			"{seo} articoli pubblicati da corridori di Runnea. Consigli su nutrizione, allenamento, scarpe da corsa per correre in modo sano e corretto.",
		page_title_author: "Magazine running di {seo}",
		meta_title_author: "Articoli sulla corsa {seo} - Abbigliamento e scarpe da corsa",
		meta_description_author:
			"Articoli di running da {seo} con consigli su alimentazione, allenamento, scarpe da corsa per correre in modo sano e corretto.",
		page_title_text: 'Magazine running "{seo}',
		meta_title_text: "Articoli sulla corsa  '{seo}' - Abbigliamento e scarpe da corsa",
		meta_title_tag_section: "{seo} - Abbigliamento e scarpe da corsa",
		comparator: "Comparatore",
		nutrition: "Nutrizione",
		running_training: "Allenamento per la corsa",
		sports_injuries: "Infortuni da corsa",
		running_news: "Notizie sulla corsa",
		podcast: "Podcast",
		outdoor: "Outdoor",
		electronics: "Elettronica",
		magazine: "Magazine",
	},
	article_page: {
		magazine: "RUNNEA Magazine",
		articles: "Articoli",
		categories: "Categorie",
		share: "Condividi",
		follow: "Seguici",
		comments: "Commenti",
		related_articles: "Articoli correlati",
		leave_comment: "Lascia un commento",
		registered: "Devi essere loggato per scrivere un commento",
		register: "Registrati",
		post: "Pubblicare",
		notifications: "Vuoi ricevere le notifiche quando un utente partecipa a questa conversazione?",
		subscribe: "Iscriviti",
		reportage: "Servizio",
		copy_to_clipboard: "Copia l'URL nei tuoi appunti",
		copied_to_clipboard: "URL copiato nei tuoi appunti",
		posted_on: "Inserito il",
		read_more_category: "Leggi altre notizie su: ",
	},
	author_list: {
		page_title: "Specialisti della corsa",
		meta_title: "Specialisti in corsa, nutrizione, allenamento e tester di scarpe da corsa",
		meta_description:
			"Specialisti in corsa, nutrizione, allenamento, scarpe da corsa per correre in modo corretto e aiutarti a scegliere la scarpa da corsa più adatta alle tue caratteristiche e comprarla al miglior prezzo.",
		page_title_articles: "Autori di articoli sulla corsa",
		meta_title_articles: "Specialisti in corsa, nutrizione, allenamento e tester di scarpe da corsa.",
		meta_description_articles:
			"Specialisti in corsa, nutrizione, allenamento, scarpe da corsa per correre in modo corretto e aiutarti a scegliere la scarpa da corsa più adatta alle tue caratteristiche e comprarla al miglior prezzo.",
		page_title_reviews: "tester {seo}",
		meta_title_reviews: "tester {seo}",
		meta_description_reviews:
			"Specialisti in corsa, nutrizione, allenamento, scarpe da corsa per correre in modo corretto e aiutarti a scegliere la scarpa da corsa più adatta alle tue caratteristiche e comprarla al miglior prezzo.",
		authors: "autori.",
		showing_from: "Mostrando da",
		showing_to: "a",
	},
	author_page: {
		magazine: "Magazine running di",
		articles: "articoli",
	},
	brand_list: {
		brands: "Marche",
		title: "Marche di prodotti per la corsa | Runnea",
		description: "Tutte le marche di prodotti da corsa nel nostro catalogo, per aiutarti a scegliere la migliore scarpa da corsa per te.",
		marks: "Marche di prodotti per la corsa",
	},
	store_page: {
		brands: "Marche principali",
		categories: "Categorie di prodotti",
		since: "Negozio partner di Runnea dal ",
		reviews: "Recensioni",
		best_sold: "I più venduti di",
	},
	calculator: {
		page_title: "Calcolatore di tempi",
		configure_sessions:
			"Imposta le tue sessioni di allenamento e noi calcoliamo la somma di tutti gli intervalli, i tempi intermedi dopo ogni intervallo e i tempi intermedi per chilometro. Potrai anche stampare il risultato o salvarlo per poterlo visualizzare quando vuoi.",
		how_works: "Come funziona",
		fill_two: "Compila due dei tre campi",
		of_each_interval: "di ogni intervallo e calcoleremo il terzo.",
		insert_up: "inserisci fino a 20 intervalli.",
		added_intervals: "Gli intervalli aggiunti possono essere ordinati o cancellati.",
		can_also: "Si può anche inserire un gruppo di intervalli",
		to_which:
			"a cui si può assegnare un numero di ripetizioni. All'interno del gruppo, gli intervalli possono essere ordinati o cancellati.",
		save_your: "Salva le tue sessioni di allenamento e potrai accedervi quando vuoi.",
		to_save: "Per salvare le sessioni devi essere loggato.",
		interval: "Intervallo",
		distance: "Distanza",
		acronym_meters: "(Mts)",
		rhythm: "Ritmo",
		acronym_rhythm: "(Min/Km)",
		time: "Tempo",
		actions: "Azioni",
		raise_position: "Aumenta la posizione",
		lower_position: "Diminuisci posizione",
		remove: "Cancellare",
		new_interval: "Nuovo intervallo di gruppo",
		total: "Totale",
		see_hide: "Visualizzare / nascondere i parziali",
		print: "Stampa",
		save: "Salva",
		modify: "Modificare",
		group: "Gruppo",
		delete_all: "Cancellare tutto",
		calculate: "Calcola",
		calculate_training: "Calcola i tuoi tempi di allenamento",
		calculate_marathon: "Calcola i tempi della tua maratona",
		calculate_half_marathon: "Calcola i tuoi tempi di una mezza maratona",
		calculate_ten: "Calcola i tuoi tempi di una 10K",
		most_search: "I più cercati",
		adv: "Adv.",
		banner: "Banner",
		reps: "Reps.",
		error_missing: "Alcuni degli intervalli non hanno i valori richiesti.",
		error_empty: "Svuota qualche campo per ricalcolare",
		error_not_valid: "Questi valori non sono validi, per favore correggili.",
		error_need: "Abbiamo bisogno di un minimo di due valori per fare il calcolo.",
	},
	calculator_10K: {
		page_title: "Calcolatrice del tempo di  una10K",
		insert_rhythm:
			"Inserisci il tuo ritmo e noi calcoleremo il tempo che faresti nei 10 Km o inserisci il tempo che vuoi fare e noi calcoleremo il ritmo che ti serve per fare i 10 Km.",
	},
	calculator_half_marathon: {
		page_title: "Calcolatrice del tempo di una mezza maratona",
		insert_rhythm:
			"Inserisci il tuo ritmo e noi calcoleremo il tempo che faresti in una mezza maratona o inserisci il tempo che vuoi fare e noi calcoleremo il ritmo necessario per fare una mezza maratona.",
	},
	calculator_marathon: {
		page_title: "Calcolatore del ritmo di una maratona",
		insert_rhythm:
			"Inserisci il tuo ritmo e noi calcoleremo il tempo che faresti in una maratona o inserisci il tempo che vuoi fare e noi calcoleremo il ritmo necessario per fare una maratona.",
	},
	check_in: {
		checking_user: "Stiamo controllando il tuo utente",
	},
	comparator_page: {
		page_title: "Comparatore {seo}",
		meta_title: "Comparatore {seo}",
		meta_description: "comparatore {seo}, per aiutarvi a scegliere {seo_the_best}.",
		in: "In",
		add: "Aggiungi",
		best: "Migliore",
		price: "prezzo",
		money_symbol: "€",
		reviews: "Recensioni",
		queries: "Consultare",
		benefits: "Prestazioni",
		characteristics: "Caratteristiche",
	},
	comparison_page: {
		page_title: "{seo}",
		meta_title: "Confronto - {seo}",
		meta_description: "Dati per confrontare e scegliere se comprare un {seo}, vedendo il confronto online su una sola pagina web.",
		in: "In",
		best: "Migliore",
		price: "prezzo",
		money_symbol: "€",
		opinions: "Opinioni",
		queries: "Consultazioni",
		benefits: "Prestazioni",
		characteristics: "Caratteristiche",
		soon: "Prossimamente",
	},
	discount_codes_list: {
		page_title: "Buoni sconto nei negozi di corsa {year}",
		meta_title: "Buoni sconto per comprare le tue scarpe da corsa al miglior prezzo",
		meta_description: "Tutti i coupon di sconto disponibili nei negozi di corsa per rendere il vostro shopping più economico.",
		go_to_shop: "Vai al negozio",
		coupon: "Codice sconto",
		copy_code: "Copia codice",
	},
	gallery_page: {
		review: "Review",
		opinions: "Opinioni",
		prices: "Prezzi",
		queries_recommendations: "Domande e consigli",
		photos: "Foto",
		news: "Notizie",
	},
	index: {
		covers: "Cover",
		user_profile: "Profilo utente",
		sheet: "Scheda",
		sheet_of: "Scheda di",
		product: "Prodotto",
		products: "Prodotti",
		prices: "Prezzi",
		race: "Gara",
		marks: "Marche",
		article: "Articolo",
		articles: "Articoli",
		reviews: "Recensioni",
		author: "Autore",
		authors: "Autori",
		cupons: "Buoni sconto",
		popular_race: "Gare podistiche",
		query: "Richiesta",
		queries: "Richieste",
		galery: "Galleria",
		comparative: "Confronto",
		list: "Elenchi",
		list_of: "Elenco di",
		filter_product: "(filtrato per prodotto)",
		filter_author: "(filtrato per autore)",
		filter_two: "(filtrato per autore e prodotto)",
		conList: "Articoli, Autori, Domande e Gallerie",
		calculator: "Calcolatrice",
		calculators: "Calcolatrici",
		marathon: "Maratona",
		half_marathon: "Mezza maratona",
		ten_k: "10 km",
		link: "Link",
		redirecting: "Reindirizzamento al prezzo esterno",
		components: "Componenti",
		crisis: "(Creativecrisis),",
	},
	inquiry_list: {
		meta_title: "{seo} | Consigli di Runnea",
		meta_description: "",
		query: "richiesta",
		inquiries: "richieste",
		inquiries_pl: "richieste",
		inquiries_types_todas: "Tutti",
		inquiries_types_1: "La mia scarpa ideale",
		showing_from: "Mostrando da",
		showing_to: "a",
		find: "Cerca",
		quick_search: "Ricerca rapida",
		category: "Categoria",
		ask: "Fare una",
		consultorio: "Team Runnea",
		make_a: "Fare una",
		required_field: "Campo obbligatorio",
		receive_notifications: "Voglio ricevere notifiche quando un utente partecipa a questa richiesta",
		consult: "Fai una richiesta",
		thanks: "Grazie per averci fatto questa domanda",
		error_try_again: "C'è stato un errore nell'invio della tua richiesta. Si prega di riprovare più tardi.",
		write: "Inserisci il tuo testo qui",
		search_advanced: "Ricerca avanzata",
		search_keyword: "Parola chiave",
	},
	price_list: {
		outlet: "outlet",
		page_title: "Outlet di {seo}",
		subpage_title: "Saldi {year} grandi sconti {seo}",
		meta_title: "Outlet di {seo} - Offerte e recensioni di shopping online",
		meta_description:
			"Listino prezzi di {seo}, con recensioni e valutazioni degli utenti per scegliere la migliore scarpa da corsa per atleti al miglior prezzo.",
		in_value: "in {value}",
		of_value: "da {value}",
		for_value: "per {value}",
		with_value: "con {{value}",
		only_value: " {value}",
		filter_value: "{filter} {value}",
		in_filter_value: "in {filter} {value}",
		of_filter_value: "di {filter} {value}",
		for_filter_value: "per {filter} {value}",
		order_by: "Ordina per",
		show: "Mostra",
		shops: "Negozi",
		lowcost: "Economici",
		not_found: "I filtri che hai selezionato per",
		not_found_extra: "non hanno dato alcun risultato.",
		page: "Pagina",
		prices_of_product_type: "Prezzi di {type}",
		of: "da",
	},
	product_list: {
		page_title: "Prodotti{filtros}",
		meta_title: "Prodotti per la corsa{filtros}- Offerte per comprare online",
		search_title: "Risultati per ",
		search_product_title: "Risultati dei prodotti per la ricerca ",
		search_article_title: "Articoli che contengono ",
		not_found: "Non abbiamo trovato prodotti o articoli per la ricerca ",
		feedback_text: "Non trovi quello che stai cercando?",
		feedback_action: "Invia i tuoi suggerimenti",
		feedback_link: "/suggerimenti.html",
		show_more: "Mostra di più",
		meta_description:
			"Elenco di prodotti per la corsa{filtros}, con opinioni e valutazioni di corridori per scegliere i migliori prodotti per la corsa e il running al miglior prezzo.",
		list: "Elenco di",
		show: "Mostra",
		of: "da",
		pag: "Pagina",
		list_of: "Elenco di",
		filters: "Filtri",
		brand: "Marchio",
		gender: "Genere",
		price: "Prezzo",
		drop: "Drop",
		droptie: "Drop",
		cushioning: "Ammortizzazione",
		constitution: "Costruzione",
		footprint: "Impronta",
		surface: "Superficie",
		use: "Uso",
		beat: "Ritmo",
		foot: "Piede",
		support: "Appoggio",
		distance: "Distanza",
		size: "Taglie",
		technology: "Tecnologia",
		fabric: "Tessuto",
		store: "Negozio",
		man: "Uomo",
		woman: "Donna",
		kid: "Bambino - Bambina",
		low: "Basso",
		medium: "Medio",
		high: "Alto",
		mediumhigh: "Medio alto",
		"carbon-plate": "Piastra in carbonio",
		light: "Leggera",
		soft: "Minimalista",
		strong: "Forte",
		asphalt: "Asfalto",
		trail: "Sentiero",
		track: "Pista",
		training: "Allenamento",
		competition: "Gara",
		mixed: "Vari",
		dig: "Cavo",
		flat: "Flat",
		normal: "Normale",
		tiptoe: "Punta",
		heel: "Tacco",
		pronator: "Pronatore",
		neutral: "Neutro",
		supinator: "Supinatore",
		short: "Brevi distanze",
		maraton: "Maratona",
		"half-maraton": "Mezza maratona",
		"10k": "10K",
		in_value: "in {value}",
		of_value: "di {value}",
		for_value: "per {value}",
		with_value: "con {value}",
		only_value: "{value}",
		filter_value: "{filter} {value}",
		in_filter_value: "in {filter} {value}",
		of_filter_value: "di {filter} {value}",
		for_filter_value: "per {filter} {value}",
		order_by: "Ordina per",
		visit: "Visita il nostro",
		text: "Testo",
		type: "Tipo",
		outlet: "outlet",
		showing_from: "(Mostrando i risultati da",
		to: "a",
		new: "Nuovo",
		products: "Prodotti",
		the: "il",
		most: "più",
		sold_out_fem: "vendute",
		sold_out_masc: "venduti",
		about: "su",
		relevance: "rilevanza",
		module_products_opinion_female: "{products} più commentate dagli utenti",
		module_products_opinion_male: "{products} più commentati dagli utenti",
		module_reviews: "Ultime recensioni di {products}",
		top_seller: "I più venduti",
		position: "Posizione",
	},
	product_page: {
		compare: "Confronta",
		comparer: "Comparatore",
		show: "Vedi",
		show_prices: "Vedi tutti i prezzi",
		show_prices_man: "Vedi tutti i prezzi per uomo",
		show_prices_woman: "Vedi tutti i prezzi per donne",
		show_more_large: "Ulteriori informazioni su questo modello",
		show_more: "Vedere di più",
		note: "Nota del",
		note_fem: "Nota del",
		specialist: "Specialista",
		community: "Community",
		leaf: "Scheda",
		review: "Review",
		review_title: "Review da {product}",
		reviews: "Recensioni",
		reviews_likes_s: "1 persona ha trovato questo utile",
		reviews_likes_p: "{number} persone hanno trovato questo utile",
		reviews_like: "Utile",
		photos: "Foto",
		opinions: "Opinioni",
		opinions_of: "Opinioni di",
		inquiries: "Domande su",
		inquiries_of: "Domande su",
		similars: "Simili",
		news: "Notizie",
		news_of: "Notizie da",
		prices: "Prezzi",
		from: "Da",
		in: "Su",
		shops: "Negozi",
		collect: "Raccogliamo i prezzi da",
		more: "Più di",
		sellers: "Venditori",
		save: "Risparmia fino a",
		save_and: "e fino a",
		save_to: "Risparmia fino a",
		releases: "sui nuovi arrivi",
		rest: "su tutti gli altri",
		size: "taglie",
		size_no: "Non conosci la tua taglia?",
		query_table: "Controlla la tabella delle taglie di",
		username: "Nome utente",
		user_querys: "Domande degli utenti",
		doubts: "Hai domande?",
		query_expert: "Chiedi all'esperto",
		compare_with: "Confronta con",
		compare_with_label: "Confronta ",
		with: "con",
		conclusion: "Conclusione",
		average_rating: "Valutazione media",
		user_average_rating: "Valutazione media degli utenti",
		user_rating: "Voto dell'utente",
		from_users: "Dagli utenti",
		lightness: "Leggerezza",
		balance: "Stabilità",
		response: "Risposta",
		flexibility: "Flessibilità",
		damping: "Ammortizzazione",
		grip: "Grip",
		rate_product: "Valuta questa scarpa",
		rated: "gli utenti hanno valutato questo modello",
		find: "Trova",
		displaying: "Mostra",
		of: "da",
		querys: "Richieste",
		querys_comments: "Domande e commenti",
		querys_comments_of: "Domande e commenti di",
		show_comments: "Visualizza i commenti",
		make_query: "Voglio fare una richiesta",
		news_talk: "le notizie parlano di questa scarpa",
		community_talk: "Fidatevi della community Runnea, i nostri utenti parlano di",
		this: "queste",
		this_masc: "questi",
		features: "Caratteristiche",
		features_of: "Caratteristiche delle",
		features_and_opinions: "review e recensioni",
		best_information: "con informazioni per scegliere la migliore",
		buy_at: "Compra al miglior prezzo",
		best_price: "Miglior prezzo",
		benefits: "Prestazioni",
		photo_gallery: "Galleria foto",
		original_price: "Prezzo originale",
		gender: "Genere",
		sizes: "Taglie",
		choose_size: "Seleziona la tua taglia",
		no_prices_soon: "Negli ultimi giorni non abbiamo rilevato alcun prezzo per:",
		no_prices_soon_vars:
			"Negli ultimi giorni non abbiamo rilevato prezzi per: {product} con taglia {filtersSize}. Prova a selezionare una taglia diversa.",
		your_selection: "La tua selezione",
		no_prices: "Siate i primi a ricevere un avviso quando i prezzi saranno disponibili.",
		photo_galery: "Galleria foto",
		lower_query: "richieste",
		lower_opinions: "recensioni",
		min: "Min:",
		max: "Max:",
		anonymous: "Anonimo",
		no_comments: "Nessun commento",
		no_queries: "Non ci sono domande",
		no_opinions: "Non ci sono recensioni",
		be_first_opinion: "Sii il primo a scrivere una recensione",
		more_compared: "più comparato",
		size_guide: "Guida alle taglie",
		tester: "Tester",
		testers: "I nostri tester vi dicono la loro opinione",
		added_product: "Il prodotto è stato aggiunto alla tabella di confronto",
		go_to_comparator: "Vai al comparatore",
		see_more_prices: "Vedi altri prezzi",
		more_prices_text: "Vedi altri prezzi",
		more_prices_of: "per",
		featured_prices: "Le migliori offerte",
		also_from: "Anche da",
		characteristics: "Caratteristiche del",
		with_information: "con informazioni per scegliere il migliore",
		money_acronim: "EUR",
		see: "Vedere",
		products_of: "Prodotti da",
		related_to: "relativi a questa ricerca",
		page: "Pagina",
		lower_page: "Pagina",
		score_text: "Nota RUNNEA",
		video: "Video",
		watch_video: "Guarda il video review",
		runnea_score_title: "Qual è il punteggio RUNNEA?",
		runnea_score_info:
			"È il punteggio generato in base alle caratteristiche prodotto, all'interesse, alle opinioni degli utenti e al rapporto qualità-prezzo.",
		ranking_text: "<strong>{product}</strong> sono al <b>{position} posto</b> nella classifica delle migliori {type}.",
		ranking_view: "Vedi ranking",
		awards: "Premi",
		pros: "Pro",
		cons: "Contro",
		question: "Domanda",
		question_first_letter: "D",
		answer: "Risposta",
		answer_first_letter: "R",
		answers: "Risposte",
		fan_title: "Sei un fan delle",
		fan_text: "Visualizzare tutti i modelli di:",
		expert: "Esperto",
		need_know: "Tutto quello che devi sapere",
		runner_type: "Tipo di corridore",
		ideal_for: "Ideali per",
		strengths: "Punti di forza",
		rivals: "Rivais",
		weight: "Peso",
	},
	product_prices: {
		page_title: "{seo}",
		meta_title: "{seo} | {precio_desde} | {fecha}{pagina}",
		meta_gender_title: "{seo} | {precio_desde} | {fecha}{pagina}",
		meta_description: "Listino prezzi di {seo}, con offerte per comprare online e outlet.",
		best_offer: "La migliore offerta",
		showing: "Mostra",
		showing_total_prices: "Mostra il {total} {seo}",
		users_favourites: "I preferiti dei Runneatori",
		compare_with: "Confronta con",
		find_more_products: "Trova {type} {brand}",
		in_value: "in {value}",
		of_value: "di {value}",
		for_value: "per {value}",
		with_value: "con {value}",
		only_value: "{value}",
		filter_value: "{filter} {value}",
		in_filter_value: "in {filter} {value}",
		of_filter_value: "di {filter} {value}",
		for_filter_value: "per {filter} {value}",
		show_prices: "Vedi i prezzi",
		alert: "Vi faremo sapere quando saranno disponibili",
		add_alert: "Aggiungere avviso",
		average_grade: "Valutazione media dei corridori",
		favorites: "I preferiti dei corridori",
		fill_position: "Classificato come il",
		best_note: "con le migliori valutazioni degli utenti",
		of: "da",
		from: "da",
		to: "a",
		prices: "Prezzi",
		order_by: "Ordina per",
		show_filters: "Mostra i filtri",
		prices_of: "Prezzi delle scarpe da corsa",
		page: "Pagina",
		lower_page: "Pagina",
		for_selected: "per i filtri selezionati",
		send: "Invia",
		country_from: "Ci stai visitando dal {country}?",
		country_change: "Scegli la tua geolocalizzazione",
	},
	race_list: {
		page_title: "Gare podistiche{seo}",
		meta_title: "Gare podistiche{seo}, Calendario delle migliori gare",
		meta_description: "Calendario delle gare{seo}, informazioni complete su iscrizioni, percorso, luogo e orario di partenza.",
		showing: "Mostra",
		showing_total_races_seo: "Monstrando {total} gare{seo}",
		in_value: "in {value}",
		of_value: "per {value}",
		for_value: "per {value}",
		with_value: "con {value}",
		only_value: "{value}",
		filter_value: "{filter} {value}",
		in_filter_value: "in {filter} {value}",
		of_filter_value: "di {filter} {value}",
		for_filter_value: "per {filter} {value}",
		race_year: "Correre: Gare nel {year}",
		find: "Trova la tua gara",
		not_found: "Nessun contenuto trovato per questa ricerca",
		description: "Data, termine di iscrizione, percorso e classifiche per",
		start: "Inizio",
		filters: {
			distance: "Distanza",
			surface: "Superficie",
			province: "Province",
			country: "Nazione",
			month: "Mese",
			year: "Anno",
		},
	},
	race_page: {
		meta_title: "{seo}",
		meta_description:
			"Informazioni di aiuto per la corsa in {seo} con dati di iscrizione, percorso, luogo e ora di partenza, classifiche, corridori e routine di allenamento.",
		tagline: "Data, iscrizione, percorso e informazioni generali",
		browser_title: "Correre: corse nel {year}",
		races_title: "Gare",
		score_general: "Generale",
		score_route: "Percorso",
		score_organization: "Organizzazione",
		score_ambience: "Atmosfera",
		score_refreshments: "Postazioni di ristoro",
		poster: "Cartello informativo",
		average_rating: "Valutazione media",
		from_users: "Dagli utenti",
		view_race_web: "Vedere il sito web della gara",
		leaf: "Scheda",
		inscriptions: "Iscrizione",
		route: "Percorso",
		forum: "Forum",
		verify: "Verifica",
		feature_date: "Data",
		feature_place: "Posto",
		feature_province: "Province",
		feature_country: "Paese",
		feature_surface: "Area",
		feature_time: "Orario di partenza",
		feature_participants: "Partecipanti",
		feature_contact: "Contatto",
		feature_organizer: "Organizzatore",
		feature_distance: "Distanza",
		something: "Qualcosa",
		heart_rate_monitors: "Monitoraggio della frequenza cardiaca",
		popular_races: "Gare podistiche",
		outlet: "Outlet",
		description: "Descrizione",
		will_be: "si terrà in data",
		in: "in",
		anyway_is_important: "In ogni caso, è importante confermare la data direttamente sul sito dell'organizzatore.",
		as_organizers:
			"Non appena gli organizzatori ci forniranno il percorso di questa edizione o si aprirà il periodo di iscrizione, pubblicheremo le informazioni corrispondenti, intanto,  se avete domande su questa gara, non esitate a inviare le vostre domande al",
		of: "di",
		or_consult: "o consultare il sito web degli organizzatori della gara.",
		inscription: "Data di scadenza per l'iscrizione alla",
		if_you_want: "Se volete sapere quando si apre il periodo di iscrizione per questa edizione del",
		be_aware: "rimanete sintonizzati sul nostro sito web e sul nostro forum del",
		because: "perché vi informeremo tempestivamente.",
		unfold_info: "Man mano che i dettagli del percorso di questa edizione della gara vengono rivelati, vi terremo informati.",
		will_inform: "vi terremo informati.",
		questions_doubts: "Se avete domande o dubbi sulla gara, potete inviare le vostre domande al nostro forum della gara.",
		fill_form: "Compila questo modulo in modo che possiamo convalidarlo",
		organizer: "Sei l'organizzatore della gara?",
		verify_race: "Controlla gara",
	},
	redirecting: {
		redirecting: "Ti reindirizzeremo al sito ufficiale.",
		value_proposal:
			"Su RUNNEA confrontiamo i prezzi di oltre 150 negozi online verificati per offrirvi il risultato migliore, senza alcun costo aggiuntivo.",
		comission: "Se visitate o acquistate attraverso il nostro sito web, potremmo ricevere una commissione.",
		coupon_advice: "Per avere il prezzo su cui hai cliccato, non dimenticare di usare il codice sconto!",
		price: 'Prezzo RUNNEA: ',
    	pvp: 'PVS: ',
    	updated: 'Aggiornato',
		accept_button: "Capito",
	},
	review_list: {
		page_title: "Recensioni di {seo}",
		meta_title: "Recensioni di {seo}",
		meta_description: "Recensioni di {seo} | Runnea, ti aiutano a scegliere le migliori scarpe da corsa.",
		reviews: "recensioni | recensioni | reviews",
		showing_from: "Mostrando da",
		showing_to: "a",
	},
	opinion_list: {
		page_title: "Opinioni di {seo}",
		meta_title: "Opinioni di {seo}",
		meta_description: "Runnea, ti aiuta a scegliere la migliore scarpa da corsa per te.",
		opinions: "recensioni | recensioni | recensioni",
		showing_from: "Mostrando da",
		showing_to: "a",
	},
	login: {
		session: "Accesso Runneatore",
		welcome: "Benvenuto Runneatore",
		forget: "Ho dimenticato la password",
		send: "Inviare a",
		back: "Indietro",
		genericerror: "Errore di accesso",
		closeSession: "Disconnettersi",
		no_account: "Non ho un account, voglio registrarmi",
		logout: "Logout",
		login: "Accesso",
		register: "Registra",
		incorrect_email: "Email errata",
		incorrect_password: "Password errata",
		remmember_password: "Ricorda la password",
	},
	reset_password: {
		new: "Nuova password",
		repeat: "Ripetere la password",
		send: "Inviare a",
		reset_password: "Inserisci la tua nuova password di accesso",
		change: "Cambiare la password",
		different_passwords: "Le password sono diverse",
		description: "Le password devono essere lunghe 8 caratteri, alfanumeriche e contenere lettere maiuscole.",
		successfully_update: "Hai cambiato la tua password. Ora puoi andare su login per accedere",
		unknown_error: "Errore sconosciuto",
		error: "Errore",
	},
	register: {
		username: "Nome utente",
		email: "Email",
		name: "Nome",
		sex: "Genere",
		man: "Maschile",
		woman: "Femminile",
		country: "Paese",
		postalCode: "Código postal",
		brand: "Hai un marchio preferito?",
		size: "Taglia",
		send: "Inviare a",
		facebook: "Accedi con Facebook",
		google: "Accedi con Google",
		password: "Password",
		repeat_password: "Ripetere la password",
		Emailnotcorrect: "La e mail non è corretta",
		Passwordsdontmatch: "Le password non corrispondono",
		LowercaseLetterRequired: "È richiesta una lettera minuscola.",
		UppercaseLetterRequired: "È richiesta una lettera maiuscola.",
		"8Characters": "minimo 8 caratteri.",
		OneNumber: "Un numero richiesto.",
		PasswordIncorrect: "La password non è corretta, controlla che contenga minuscole, maiuscole, più di 8 caratteri e alcuni numeri.",
		registrate: "Registrati",
		successfully_registered: "Registrato con successo",
		not_exist_email: "La email  non esiste",
		remember_ok: "Vi abbiamo inviato una e mail all'indirizzo {email}. Controlla la tua casella di posta per recuperare la tua password.",
		user_not_found: "Utente non trovato",
		error_recovering: "Errore nel recupero dei dati",
		email_exists: "La email esiste già",
		email_not_found: "La email non è stata trovata",
		missing_fields: "Campi mancanti da completare",
		incorrect_format: "Formato errato",
	},
	verify: {
		name_surname: "Nome e cognome",
		email: "Indirizzo email",
		tlf: "Numero di telefono",
		days: "Giorni della settimana in cui possiamo contattarti",
		hour: "Orario in cui possiamo contattarti",
		send: "Invia",
		monday: "Lunedí",
		tuesday: "Martedí",
		wednesday: "Mercoledí",
		thursday: "Giovedí",
		friday: "Venerdí",
		extra: "Informazioni aggiuntive",
		emailnotcorrect: "La email non è corretta",
		verified: "Grazie per aver compilato il modulo, ti contatteremo per confermare le informazioni.",
		not_verified: "Ci dispiace, qualcosa è andato storto, riprova più tardi.",
		missing_fields: "Mancano dei campi da completare",
		accept: "Accettare l'informativa",
		acceptPol: "sulla privacy",
		form_first: "Sei un organizzatore di gare ",
		form_first_after_bold: "?",
		form_second: "Grazie al processo di verifica potrai dare più visibilità gratuitamente al tuo evento.",
		form_third:
			"Se sei l’organizzatore o fai parte del team che organizza l’evento compila il modulo sottostante e ti contatteremo per finalizzare il processo di verifica.",
	},
	registration: {
		already_account: "Ho già un account, voglio accedere",
		continue: "REGISTRAZIONE",
	},
	talk_page: {
		consultant: "Team",
	},
	user_page: {
		modify: "Modificare la foto",
		save: "Salvare le modifiche",
		select: "Seleziona",
	},
	not_found_page: {
		pageNotFound: "Pagina non trovata",
		pageNotFoundMsg: "Siamo spiacenti, pagina non trovata. Puoi tornare indietro o tornare alla pagina iniziale.",
		goHome: "Vai alla pagina iniziale",
	},
	article_finder: {
		find_here: "Cerca un articolo nel nostro magazine",
	},
	AuthorLeaf: {
		has_written: "Hai scritto",
		num_reviews_of: "0 recensioni per | 1 recensione per | {count} recensioni per",
		of: "da",
		reviews: "recensioni",
		articles: "articoli",
	},
	AuthorMiniLeaf: {
		by: "Da",
	},
	BrandCard: {
		size_chart: "Convertitore di taglie",
		go_to_size_chart: "Vai al convertitore di taglie",
	},
	BrandsProduct: {
		also: "Anche da",
		from: "Da",
		money: "€",
		find: "Trova le scarpe da corsa",
	},
	DiscountCodeCard: {
		see: "Visualizza lo sconto",
		share: "Condividi",
		go_to_store: "Vai al negozio",
		copied: "Codice copiato",
		special_cupon: "Sconto speciale",
		date_up_to: "Fino al",
		read_more: "Leggi di più",
	},
	ModuleProductFeatureCard: {
		our_selection: "La nostra selezione",
	},
	InquiryTitleCard: {
		other_queries: "Altre domande sulla corsa",
	},
	ModuleCarousel: {
		new: "Novità!",
		upper_new: "NUOVE",
		discover: "Scoprile",
		exclusive: "Esclusiva",
		text_1: "Arrivano le gare virtuali di Runnea Academy Runnea Academy Runnea  Aca",
		text_2: "Da Runnea trovi la lista delle migliori gare virtuali del mondo.",
	},
	ModuleChats: {
		title: "Domande e commenti da {content}",
		showing: "Mostrando {start} di {end}query",
		show_all: "Visualizza le richieste di {total} utenti",
		no_contents: "Non ci sono domande o commenti",
	},
	ModuleComments: {
		title: "Commenti di {content}",
		showing: "Mostrando {start} di {end}  commenti",
		show_all: "Visualizza {total} richieste utente",
		no_contents: "Nessun commento",
	},
	ModuleForm: {
		title: "Contatti",
		field: "Il campo",
		is_required: "è obbligatorio",
		query_received: "Abbiamo ricevuto la sua richiesta, la contatteremo entro 48h. Grazie per averci contattato runneatore!",
		error_submitting: "Errore nell'invio del modulo",
		go_to_form: "Vai al modulo",
		confirmButton: "Capito",
	},
	ModuleMenu: {
		running_shoes: "Scarpe da corsa",
		trekking_shoes: "Scarpe da trekking",
		trail_shoes: "Scarpe da trail running",
		treadmill: "Tapis roulant",
		pulsometer: "Monitoraggio della frequenza cardiaca",
		running_training: "Allenamento corsa",
		menu: "Menu",
		login: "Log in",
		logout: "Logout",
		account: "Il mio account",
		title_ra: "Personal Trainer Running e Trail Running Online",
		claim: "Ti aiutiamo a correre meglio!",
		categories: "Tutte le categorie",
	},
	ModulePricesBargain: {
		new: "Nuova offerta",
		discount: "di sconto",
		go: "Vai all'offerta",
		click: "1 clic",
		clicks: "{clicks} clic",
	},
	newChatButton: {
		description:
			"Si tienes dudas en un tema específico, puedes elegir a un experto para que te ayude. O si lo prefieres, puedes lanzar una pregunta a la comunidad y que te responda cualquiera de nuestros expertos u otros usuarios.",
		options: "Elige una opción",
		your_inquiry: "Tu consulta",
		your_inquiry_for: "Tu consulta para:",
		to_do: "Eseguire",
		quest: "Esegui.",
		do: "Esegui una domanda.",
		inquiry: "richiesta | richieste",
		inquiries: "nessuna domanda | una domanda | alcune domande",
		select_category: "Seleziona una categoria",
		inquiry_description: "Descrizione della richiesta",
		write_your_text: "Scrivi qui il tuo testo",
		required_field: "Campo obbligatorio",
		receive_notifications: "Voglio ricevere notifiche quando un utente partecipa a questa query",
		not_logged_in: "Non sei loggato",
		please_login_msg: "Non sei loggato. Per favore, accedi",
		thanks: "Grazie per aver presentato la tua domanda",
		try_again: "C'è stato un errore nell'invio della tua richiesta. Si prega di riprovare più tardi.",
		mentioned_shoes: "Scarpe da corsa menzionate",
		new_inquiry: "Nuova domanda",
		new_inquiry_text: "Se hai dubbi sul materiale da running, i nostri esperti possono aiutarti.",
		modal: {
			title: "Pregunta a un experto",
			text: "Si necesitas aclarar cualquier duda sobre material de running escríbenos y nuestro equipo de expertos te ayudará en todo lo que pueda.",
		},
	},
	newCommentButton: {
		reply: "Rispondi a",
		inquiry: "richiesta | richieste",
		write_your_text: "Scrivi qui il tuo testo",
		required_field: "Campo obbligatorio",
		receive_notifications: "Voglio ricevere delle notifiche quando un utente partecipa a questo argomento.",
		not_logged_in: "Non sei loggato",
		please_login_msg: "Non sei loggato. Per favore, accedi",
		thanks: "Grazie per aver partecipato all'argomento",
		try_again: "C'è stato un errore nell'invio del tuo commento. Si prega di riprovare più tardi.",
	},
	newOpinionButton: {
		new: "Lascia la tua opinione",
		not_logged_in: "Non sei loggato",
		please_login_msg: "Non sei loggato. Si prega di effettuare il login.",
		performance: "Caratteristiche",
		comment: "Commento",
		opinion: "Recensire",
		score: "Punteggio",
		write: "Scrivi la tua recensione",
		thanks: "Grazie per la tua recensione.",
		next: "Tra poche ore vedrai la tua opinione pubblicata sul web.",
		try_again: "C'è stato un errore nell'invio della tua recensione. Si prega di riprovare più tardi.",
	},
	newCommentModule: {
		leave_comment: "Lascia un commento",
		registered: "Devi essere loggato per inviare un commento",
		register: "Registra",
		placeholder: "Scrivi la tua recensione",
		required_field: "Campo obbligatorio",
		post: "Invia",
		thanks_msg: "Commento inviato. Grazie per la tua partecipazione.",
		error_msg: "C'è stato un errore nell'invio del commento. Si prega di riprovare più tardi.",
		rgpd: "",
	},
	NewsLettersCard: {
		message: "Scoprite tutte le ultime novità sul mondo della running e le offerte sulle attrezzature sportive.",
		highlight: "Non volete perdervela?",
		remember: "Ricordati di accettare i termini e condizioni",
		successful: "Ti sei già iscritto alla nostra newsletter, Runneatore.",
		error: "C'è stato un errore, Runneatore. Si prega di riprovare più tardi",
		error_try: "C'è stato un errore. Si prega di riprovare più tardi",
		required: "Campo obbligatorio",
		thanks: "Grazie",
	},
	ProductCardPrincipal: {
		see: "Vedi scheda",
		see_description: "Vedi tutte le caratteristiche",
	},
	ProductHeaderButtons: {
		alert_received:
			"Perfetto, la tua allerta prezzo è stato configurata correttamente, se rileviamo un prezzo che soddisfa i tuoi criteri ti invieremo un avviso.",
		error_submitting: "Non è stato possibile creare l'allerta prezzo. Si prega di riprovare.",
		compare: "Confronta",
		alert: "Voglio essere informata se il prezzo scende",
		available: "Avvisami non appena sarà disponibile",
		disable_alert: "Avviso prezzo",
		disable_price_alert: "Disattiva avviso prezzo",
		close: "Chiudi",
		our_comparator:
			"Il nostro comparatore esamina moltissimi prezzi ogni giorno. Imposta la tua allerta prezzo e se troviamo il modello che stai cercando ad un un prezzo che soddisfa i tuoi criteri, ti invieremo un avviso.",
		model: "Allerta prezzo per le",
		sure: 'Clicca sul pulsante "Conferma" per disattivare questo avviso prezzo.',
		confirm: "Conferma",
		alert_deleted: "Hai cancellato con successo l'avviso di prezzo che avevi configurato per il modello {marca} {producto}.",
		error_deleting_alert:
			"Non siamo riusciti a rimuovere l'avviso di prezzo che avevi impostato per il modello {marca} {producto}. Per favore, mettiti in contatto con noi per poter rimuoverlo.",
		new_version: "Nuova versione disponibile",
		new_version_btn: "Visualizza la nuova versione",
		old_version: "Il modello attuale è:",
		old_version_btn: "Vedi Scheda",
	},
	ProductFeedback: {
		chat_1: "Ciao{user}",
		chat_2: "Cosa ci puoi dire sulle {product}?",
		state_wish: "Le adoro",
		state_have: "Le ho già",
		state_dislike: "Non mi piacciono",
		votes: "voti",
		response_wait: "...",
		response_ok: "Grazie per aver partecipato!",
		response_ko: "ERROR",
	},
	ContentPremium: {
		title: "Accedi con il tuo account per visualizzare i contenuti",
		description: "Crea il tuo account GRATUITO e goditi tutti i nostri contenuti esclusivi",
		advantages_1: "Accesso illimitato agli articoli riservati",
		advantages_2: "Newsletter con contenuti personalizzati",
		advantages_3: "Avvisi e concorsi a premi",
	},
	SliderCardFinder: {
		find: "Cerca",
	},
	UserPasswordForm: {
		new_password: "Inserisci la tua email Runnea e ti invieremo una nuova password.",
	},
	UserRegisterForm: {
		continue_with: "Continua con",
		or: "o",
		start: "Compila alcuni dati e inizia a correre!",
		required: "Campi obbligatori",
		access_data: "I tuoi dati di accesso",
		incorrect_user: "Accesso errato",
		remmember_password: "Ricorda la password",
		congratulations: "Congratulazioni!",
		welcome: "Benvenuto nella community di Runnea!",
		complete: "Compila alcuni dati e inizia a correre!",
		no_account: "Non ho un account",
		name: "Nome",
		surname: "Cognome",
		user: "Utente",
		email: "Il tuo indirizzo email",
		password: "Password",
		tread_type: "Tipo di impronta",
		dont_know: "Non so",
		pronator: "Pronatore",
		neutral: "Neutro",
		supinator: "Supinatore",
		commercial_communications:
			"Desidero essere informato su promozioni e novità. Sarete informati sulle promozioni in corso, sui nuovi prodotti e sulle novità commerciali.",
		legal:
			'Accetto l\'informativa sulla privacy creando il tuo account accetti i <a href="https://www.runnea.it/note-legali.html" target="_blank" rel="noopener noreferrer" class="legal_notice">termini e le condizioni</a> di Runnea.',
		rgpd: 'Tratteremo i vostri dati per consentire la vostra registrazione sul nostro sito web e per fornirvi i nostri servizi. Potete esercitare i diritti di accesso, rettifica, cancellazione, opposizione, limitazione del trattamento e portabilità dei vostri dati scrivendoci a <a href="mailto:contacto@runnea.com" rel="nofollow">contacto@runnea.com</a>. Maggiori informazioni nell\'informativa sulla privacy: <a href="https://www.runnea.it/privacy-policy.html" target="_blank" rel="noopener noreferrer nofollow">Politica sulla privacy</a>.',
	},
	UserAuthForm: {
		wrong_password: "Nome utente o password errati",
		no_email: "La email non esiste",
	},
	influencer_card: {
		reviews: "Recensioni",
		last_review: "Ultima recensione",
		best_review: "Migliore valutazione",
		show_price: "Vedi il prezzo",
	},
	inquiry_card: {
		comments: "Vedi i commenti",
		no_comments: "Nessuna recensione",
	},
	inquiry_finder: {
		find_text: "Filtrare per testo",
		find_product: "Filtrare per prodotto",
	},
	list_filters: {
		filters: "Filtri",
		filter: "Filtra",
		return: "Torna a",
		delete: "Cancellare",
		sizes: "Taglie",
		show: "Mostra",
		results: "Risultati",
		example: "Es. Nike",
		more_brands: "Più marchi",
	},
	list_pagination: {
		before: "Precedente",
		next: "Prossimo",
		first_page: "Prima pagina",
		last_page: "Ultima pagina",
	},
	price_card: {
		shipping_costs: "Spedizione",
		from_price: "Da {price} €",
		buy: "Compra",
		go_to_store_w_price: "Vai al negozio {store} e compra per {price} £",
		free_shipping: "Spedizione gratuita",
		show_offer: "Vedi offerta",
		till: "Fino al",
		coupon: "Sconto",
		coupon_no: "Sensa Codice",
		man: "Uomo",
		woman: "Donna",
		kid: "Niño - Bambina",
		unisex: "Uomo - Donna",
	},
	product_card_price_opinion: {
		let_opinion: "Lascia una recensione",
		opinions: "Recensioni",
		questions: "Richieste",
	},
	product_finder: {
		find_here: "Cerca qui",
	},
	brand_finder: {
		find_here: "Filtrare per marca",
	},
	race_finder: {
		find_here: "Cerca una gara",
		search: "Cerca",
	},
	module_finder: {
		title: "Scarpe da corsa",
		text1: "Trova il miglior prezzo tra più di 20.000  modelli diversi",
		text2: "Confrontiamo più di 200.000 modelli di scarpe dei marchi leader",
		placeholder: "Ricerca per marca o per modello",
		need_help: "Hai bisogno di aiuto per la tua scelta?",
		just_ask: "Chiedi ai nostri specialisti",
		where_start: "Non sai da dove cominciare?",
		use_shoe_finder: "Prova il nostro SHOE FINDER!",
		search_by_brand: "Ricerca per marca",
	},
	footer: {
		about: "Chi siamo",
		newsletter: "Newsletter",
		publicity: "Pubblicità",
		product_test: "Test del prodotto",
		shops: "Il tuo negozio e i tuoi prodotti",
		products: "Prodotti",
		contact: "Contatta",
		legal: "Avviso legale",
		privacity: "Politica sulla privacy",
		cookies: "Politica dei cookie",
		work: "Lavora con noi",
		who: "Chi siamo",
		legal_information: "Informazioni legali",
		license: "",
		other_webs: "Altri siti web di RUNNEA",
		copyright: "Copyright RUNNEA",
		follow: "Seguici",
		union: "UNIONE EUROPEA",
		union_deep: "Fondo europeo di sviluppo regionale",
		union_mes:
			"Gembira Tech, S.L. nell'ambito del Programma ICEX Next Export Initiation, è stato sostenuto da ICEX e cofinanziato dal Fondo Europeo di Sviluppo Regionale (FESR). L'obiettivo di questo sostegno è quello di contribuire allo sviluppo internazionale dell'azienda e del suo ambiente.",
		union_mode: "Un modo di fare Europa",
		disclaimer: "RUNNEA potrebbe ricevere una commissione dal negozio online in caso di acquisto tramite il link di affiliazione.",
	},
	product_header: {
		specialist: "Nota dello specialista",
		community: "Nota della community",
		leaf: "Scheda",
		review: "Review",
		opinions: "Recensioni",
		inquiries: "Domande e consigli",
	},
	user_auth_form: {
		facebook: "Accedi con Facebook",
		google: "Accedi con Google",
	},
	youtube: {
		suscribe: "ISCRIVITI AL CANALE",
		review: "Video Review",
	},
	app_finder: {
		example: "Marca o modello...",
		popular: {
			title: "Scoprire",
			list: {
				1: {
					title: "Scarpe Running donna",
					link: "/scarpe-running/modelli/tutti/genere-donna/",
				},
				2: {
					title: "Scarpe Running uomo",
					link: "/scarpe-running/modelli/tutti/genere-uomo/",
				},
				3: {
					title: "Scarpe Running trail",
					link: "/scarpe-running/modelli/tutti/superficie-trail/",
				},
				4: {
					title: "Scarpe trekking",
					link: "/scarpe-trekking/modelli/tutti/",
				},
				5: {
					title: "Orologi sportivi",
					link: "/orologi-sportivi/modelli/tutti/",
				},
			},
		},
		most_searched: {
			title: "I modelli più richiesti",
			list: {
				1: {
					title: "Nike Pegasus 40",
					link: "/scarpe-running/nike/pegasus-40/1018029/",
					img: "https://static.runnea.it/images/202305/nike-pegasus-40-scarpe-running-400x400x90xX.jpg?1",
				},
				2: {
					title: "ASICS Gel Nimbus 25",
					link: "/scarpe-running/gel-nimbus-25/1006076/",
					img: "https://static.runnea.it/images/202212/asics-gel-nimbus-25-scarpe-running-400x400x90xX.jpg?2",
				},
				3: {
					title: "Adidas Ultraboost Light 23",
					link: "/scarpe-running/ultraboost-23/1006146/",
					img: "https://static.runnea.com/images/202302/adidas-ultraboost-23-zapatillas-running-400x400x90xX.jpg?1",
				},
				4: {
					title: "New Balance FuelCell SC Elite v3",
					link: "/scarpe-running/new-balance/fuelcell-sc-elite-v3/1004943/",
					img: "https://static.runnea.it/images/202302/new-balance-fuelcell-sc-elite-v3-scarpe-running-400x400x90xX.jpg?3",
				},
				5: {
					title: "Garmin Fenix 7",
					link: "/orologi-sportivi/garmin/fenix-7/18423/",
					img: "https://static.runnea.com/images/202201/garmin-fenix7-ficha-caracteristicas-precios-400x400x80xX.jpg?1",
				},
			},
		},
	},
	consent_cookie: {
		consent_modal: {
			title: "Utilizziamo i cookie per offrirvi la migliore esperienza",
			description:
				'Utilizziamo i nostri cookie per garantire il funzionamento di base del sito e cookie di terze parti per ottenere dati statistici sulla vostra navigazione e per pubblicizzare prodotti personalizzati. Per ulteriori informazioni è possibile consultare il nostro <a href="/cookie-policy.html">politica dei cookie</a>, <a href="/note-legali.html">avviso legale</a> e <a href="/privacy-policy.html">Politica sulla privacy</a>, <a href="https://policies.google.com/privacy" target="_blanc">Informativa sulla privacy di Google</a>.',
			primary_btn: "Accettare tutti",
			secondary_btn_1: "Gestione dei cookie",
			secondary_btn_2: "Rifiutare tutti",
		},
		settings_modal: {
			title: "Gestione dei cookie",
			save_settings_btn: "Salvare la configurazione",
			accept_all_btn: "Accettare tutti",
			reject_all_btn: "Rifiutare tutti",
			close_btn_label: "Chiudere",
			cookie_table_headers: {
				col1: "Nome",
				col2: "Dominio",
				col3: "Tempo",
				col4: "Descrizione",
			},
			blocks_1: {
				title: "Utilizzo dei cookie",
				description:
					'Utilizziamo cookie propri per garantire il funzionamento di base e offrire la migliore esperienza e cookie di terze parti per ottenere dati statistici sulla navigazione e pubblicizzare prodotti personalizzati. Per ulteriori informazioni è possibile consultare il nostro <a href="/cookie-policy.html">politica dei cookie</a>, <a href="/note-legali.html">avviso legale</a> e <a href="/privacy-policy.html">Politica sulla privacy</a>, <a href="https://policies.google.com/privacy" target="_blanc">Informativa sulla privacy di Google</a>.',
			},
			blocks_2: {
				title: "Cookie strettamente necessari",
				description:
					"Questi cookie sono essenziali per il corretto funzionamento del sito web. Senza questi cookie il sito web non potrebbe funzionare correttamente.",
			},
			blocks_3: {
				title: "Cookie funzionali",
				description:
					"Questi cookie sono importanti per il corretto funzionamento del sito web. Senza questi cookie non possiamo offrirvi la migliore esperienza.",
			},
			blocks_4: {
				title: "Cookie analitici e di performance",
				description: "Questi cookie ci permettono di misurare in modo anonimo l'utilizzo e le prestazioni del sito web.",
			},
			blocks_5: {
				title: "Cookie pubblicitari e di marketing",
				description: "Questi cookie ci permettono di pubblicizzare prodotti personalizzati all'utente.",
			},
			cookies: {
				hash: {
					expiration: "Sessione",
					description:
						"Memorizza le informazioni della pagina da cui viene eseguito il processo di login e registrazione quando si utilizza il login con i social network (Google o Facebook)",
				},
				auth_strategy: {
					expiration: "1 mese",
					description: "Memorizza le informazioni del sistema di login scelto dall'utente (Local, Google o Facebook)",
				},
				cc_runnea: {
					expiration: "1 año",
					description: "Memorizza le informazioni sulle preferenze dell'utente in materia di cookies",
				},
				brander: {
					expiration: "Tra 1 giorno e 1 anno",
					description: "Salva le informazioni sulla visualizzazione dei livelli informativi in modo che non vengano più visualizzati",
				},
				ga: {
					expiration: "2 anni",
					description:
						"Questi cookie vengono utilizzati da Google Analytics per distinguere gli utenti unici assegnando un numero generato casualmente",
				},
				gid: {
					expiration: "2 anni",
					description:
						"Questi cookie vengono utilizzati da Google Analytics per generare un identificativo univoco dell'utente per contare quante volte un utente visita il sito, nonché la data della prima e dell'ultima visita",
				},
				fbp: {
					expiration: "3 mesi",
					description:
						"Utilizzato da Facebook per fornire una serie di prodotti pubblicitari, come le offerte in tempo reale di inserzionisti esterni",
				},
				gads: {
					expiration: "13 mesi",
					description:
						"Insieme al servizio DoubleClick for Publishers di Google, è progettato per misurare le interazioni con gli annunci e per evitare che vengano mostrati ripetutamente",
				},
			},
		},
	},
	recommender: {
		seo: {
			title: "Shoe Finder: una guida per aiutarvi a scegliere la scarpa da acquistare | RUNNEA",
			description:
				"Una guida che vi aiuterà, con alcune informazioni, a trovare la scarpa running, da trail, da trekking o sneaker più adatta a voi.",
		},
		tabs: {
			start: {
				text: "Ciao runneatore, sei alla ricerca di un nuovo paio di scarpe da corsa? Ti aiuteremo a trovare la soluzione più adatta a te e al tuo budget in pochi semplici passi.",
				button: "Inizio",
				results: "Vedi risultati",
				autor: "Gorka Cabañas",
				specialist: "Specialista in scarpe da running",
			},
			purpose: {
				title: "Stai cercando scarpe per...",
				text: "Correre sull'asfalto non è come correre in montagna. Né una scarpa da passeggio è uguale a una scarpa di moda. Dicci che tipo di scarpe sta cercando.",
				options: {
					running: "Corse su strada",
					trail: "Trail",
				},
			},
			gender: {
				title: "Con quale genere ti senti identificato?",
				text: "Nella ricerca di un modello scarpe dobbiamo basarci su due opzioni principali.",
				options: {
					man: "Uomo",
					woman: "Donna",
				},
			},
			size: {
				title: "Indica la tua taglia",
				text: "Consiglio RUNNEA: Il team di podologi RUNNEA consiglia di scegliere scarpe per lo sport di mezzo numero in più rispetto a quello che si usa di solito.",
				options: {
					default: "Scegli la taglia",
				},
			},
			footprint: {
				title: "Tipo di falcata",
				text: "Se non conosci la tua falcata, ti consigliamo di fare un'analisi dell'impronta. Ti aiuterà a evitare infortuni e a correre in modo più sicuro.",
				options: {
					pronator: "Pronatore",
					neutral: "Neutro",
					supinator: "Supinatore",
					none: "Non so",
				},
			},
			constitution: {
				title: "La mia costituzione...",
				text: "La nostra corporatura influisce in modo decisivo sulla scelta della scarpa. Più siamo pesanti, più abbiamo bisogno di ammortizzazione.",
				options: {
					light: "Leggera",
					medium: "Media",
					strong: "Forte",
					none: "Non so",
				},
			},
			cushioning: {
				title: "Che tipo di ammortizzazione cerchi?",
				text: "Preferisci un'ammortizzazione più solida che ti aiuti a migliorare i tuoi tempi o cerchi il comfort e la massima ammortizzazione, sacrificando però la reattività?",
				options: {
					soft: "Veloce e reattiva",
					neutral: "Intermedia",
					high: "Massima ammortizzazione",
					none: "Non so",
				},
			},
			foot: {
				title: "Qual è il tuo tipo di piede?",
				text: "Conoscere il tipo di piede ci aiuta a selezionare la scarpa più adatta al suo stile di corsa.",
				options: {
					normal: "Normale",
					dig: "Cavo",
					flat: "Plano",
					none: "Non so",
				},
			},
			use: {
				title: "Per cosa utilizzerai le scarpe?",
				text: "L'ideale sarebbe avere una scarpa per le gare e una per gli allenamenti, ma è sempre possibile scegliere una scarpa mista da usare sia per gli allenamenti che per le gare.",
				options: {
					training: "Allenamento",
					competition: "Competizione",
					mixed: "Misto",
				},
			},
			distance: {
				title: "Per quali distanze intendi allenarsi?",
				text: "Nessuno giudicherà quanti chilometri corri, ma già che ci siamo, sei più un corridore di breve o di lunga distanza?",
				options: {
					short: "Corta",
					"10K": "10K",
					"half-maraton": "Mezza maratona",
					maraton: "Maratona",
					"ultra-trail": "Ultra trail",
				},
			},
			beat: {
				title: "A che ritmo corri di solito?",
				text: "Sei fra coloro che non guardano l'orologio quando vanno a correre o, al contrario, ti alleni ogni giorno per migliorare le tue prestazioni?",
				options: {
					high: "Fino a 4:30 Min/Km",
					medium: "4:30 a 5:30 Min/Km",
					low: "5:30 Min/Km e più",
					none: "Non so",
				},
			},
			brand: {
				title: "Hai un marchio preferito?",
				text: "Sei fedele al tuo marchio preferito o sei aperto a provare nuove opzioni?",
				options: {
					default: "Scegliere il marchio",
					none: "Sorprendimi!",
				},
			},
			price: {
				title: "Quanto pensi di spendere?",
				text: "Il budget è importante, ma noi di RUNNEA vogliamo aiutarti a trovare la tua scarpa ideale al miglior prezzo.",
				options: {
					low: "Fino a 60€",
					medium: "Fino a 100€",
					high: "100€ e più",
					none: "No importa",
				},
			},
			result: {
				texts: {
					loading: "Stiamo cercando le scarpe che meglio si adattano alle caratteristiche che avete appena configurato...",
					contents: "Le scarpe migliori per voi sono...",
					void: "Non abbiamo trovato scarpe con tutte le caratteristiche che ci hai indicato. Prova il nostro motore di ricerca di scarpe running:",
					more_results: "Puoi vedere altri risultati qui",
				},
				product: {
					index: "Vedi caratteristiche",
					prices: "Vedi tutti i prezzi",
				},
				buttons: {
					contents: "Vedi altre opzioni",
					void: "Vedi altre opzioni",
				},
			},
		},
	},
	runningMusic: {
		imgCalienta: "https://static.runnea.com/images/202309/calienta.jpg?1",
		imgInfoIntencidad: "https://static.runnea.com/images/202309/info-intensidad.png?1",
		imgIntencidad: "https://static.runnea.com/images/202309/intensidad.png?1",
		imgPop: "https://static.runnea.com/images/202309/pop.png?1",
		imgRock: "https://static.runnea.com/images/202309/rock.png?1",
		imgSession01: "https://static.runnea.com/images/202309/sesion-01.png?1",
		imgSession02: "https://static.runnea.com/images/202309/sesion-02.png?1",
		imgSession03: "https://static.runnea.com/images/202309/sesion-03.png?1",
		imgTechno: "https://static.runnea.com/images/202309/techno.png?1",
		imgUrban: "https://static.runnea.com/images/202309/urban.png?1",
	},
	recomend: {
		title: "Recomendador de Zapatillas",
		subTitle: "Respuesta inmediata",
		descriptions: "Te guía con pasos sencillos para escoger tu zapatilla",
	},
};
